import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
      <div class='social-links'>
            <Link  class='social-links-design' onClick={()=>{window.open('https://www.notion.so/flamingocards/Miss-o-e-Cultura-ff6a5fe6177441e09900d800628de0c7?pvs=4')      }}>{'sobre nós'+'\u00A0'}</Link>
            <Link  class='social-links-design' onClick={()=>{window.open('mailto:flamingocards.help@gmail.com?subject=Suporte&body=Olá%20Cartas%20Flamingo,')      }}>contactos</Link>
            <Link  class='social-links-design' onClick={()=>{window.open('https://www.notion.so/flamingocards/Termos-de-Uso-e760ebd6eb224f05b4d8ea1eececd52f?pvs=4')      }}>termos</Link>
            <Link  class='social-links-design' onClick={()=>{window.open('https://www.notion.so/flamingocards/Pol-tica-de-Privacidade-92cfc5af9adf480f9ce6fb1eece57bf6?pvs=4')      }}>privacidade</Link>
            <Link  class='social-links-design' onClick={()=>{window.open('https://perguntasflamingo.com/')}}>iOS</Link>
      </div>
      <div class='social-icons'>
            <Link
              class='social-icon-link tiktok'
              to={{ pathname: 'https://www.tiktok.com/@perguntasflamingo' }}
              target='_blank'
              aria-label='Tiktok'
            >
              <i class='fab fa-tiktok fa-x' />
            </Link>
            <Link
              class='social-icon-link facebook'
              to={{ pathname: 'https://www.facebook.com/people/flamingocardsco/100090486347949/' }}
              target='_blank'
              aria-label='Facebook'
            >
              <i class='fab fa-facebook-f' />
            </Link>
            <Link
              class='social-icon-link instagram'
              to={{ pathname: 'https://www.instagram.com/flamingocards.co/?igshid=YmMyMTA2M2Y%3D' }}
              target='_blank'
              aria-label='Instagram'
            >
              <i class='fab fa-instagram' />
            </Link>
            <Link
              class='social-icon-link youtube'
              to={{ pathname: 'https://www.youtube.com/@flamingocards' }}
              target='_blank'
              aria-label='Youtube'
            >
              <i class='fab fa-youtube' />
            </Link>
            <Link
              class='social-icon-link twitter'
              to={{ pathname: 'https://twitter.com/flamingocard?s=11&t=GSK_7CaTApy0L0fIHS0L8Q' }}
              target='_blank'
              aria-label='Twitter'
            >
              <i class='fab fa-twitter' />
            </Link>
            <Link
              class='social-icon-link twitter'
              to={{ pathname: 'https://tipsyflamingo.co/es' }}
              target='_blank'
              aria-label='Twitter'
            >
              <i class='fab fa-apple' />
            </Link>
          </div>
        <div class='social-media-wrap'>
          <small class='website-rights'>© a criar memórias uma carta de cada vez 2023.</small>
        </div>
      </section>
    </div>
  );
}

export default Footer;
