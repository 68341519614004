import React, { useState, useEffect } from 'react';
import '../../App.css';
import './HeroSection.css';
import Lottie from 'react-lottie';
import flamingo from '../../lottie/Flamingo.json'
import apple from './download.png'
import bottom from './bottomNew.png'
import bottomAndroid from './bottomNewAndroid.png'
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC8ABWO0Mvk0bS3w8VvPOn1lfa6eKhcenw",
  authDomain: "perguntasflamingo-app.firebaseapp.com",
  projectId: "perguntasflamingo-app",
  storageBucket: "perguntasflamingo-app.appspot.com",
  messagingSenderId: "358294532103",
  appId: "1:358294532103:web:265e75f20eecd6b27a7aa2",
  measurementId: "G-RCFYQTJM2V"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function HeroSection() {
  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: flamingo,
    // initialDelay: 3000,
    // isStopped:true
    // rendererSettings: {
    //   preserveAspectRatio: "xMidYMid slice"
    // }
  };

  const [width, setWidth] = useState(window.innerWidth);

  const [autoplay, setAutoplay] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAutoplay(true);
    }, 1350); // Delay the animation by 2 seconds
    return () => clearTimeout(timeout);
  }, []);


  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  console.log(`Screen width: ${width}`);

  return (
    <div className='hero-container'>
      {/* <img className='logo' src='/images/logo.svg'/> */}
      <div className='hero-container-2'>

        <Lottie
          options={{loop: false,animationData: flamingo,autoplay:autoplay}}
          height={width >= 700 ? 1280 / 2.2 : 1280 / 4.5}
          width={width >= 700 ? 1000 / 2.2 : 1280 / 4.5}
        />

      </div>
      <text className='promo'>desafios animados e conversas</text>
      {width > 670 && (
        <img className='logo' src={apple} />
      )}
      {width < 670 && (
        <div className='bDiv'>
          <img className='bottom' src={/Android/i.test(navigator.userAgent) ? bottomAndroid : bottom} style={{ width: '370px' }} />
        </div>
      )}
      
    </div>
  );
}

export default HeroSection;
