import React, { useState, useEffect } from 'react';
import './App.css';
import Home from './components/pages/Home';
import HomePortuguesPortugal from './components/pages/HomePortuguesPortugal';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';



function App() {
  
  return (
    <>
      <Helmet>
        <meta name="description" content="Welcome to Tipsy Flamingo in English!" />
        <meta name="apple-itunes-app" content="app-id=1537890432, app-argument=https://apps.apple.com/app/apple-store/id1537890432?pt=122101512&ct=website&mt=8"/>
        <link rel="apple-touch-icon" sizes="180x180" href="/lottie/180.png" />
        <link rel="apple-touch-icon" sizes="32x32" href="/lottie/32.png" />
        <link rel="apple-touch-icon" sizes="16x16" href="/lottie/16.png" />
      </Helmet>
      <Router>
        <Switch>
          <Route path='/' exact component={HomePortuguesPortugal} />
          {/* <Route path='/es' exact component={HomePortuguesPortugal} /> */}
          {/* <Route path='/services' component={Services} />
          <Route path='/products' component={Products} />
          <Route path='/sign-up' component={SignUp} /> */}
        </Switch>
      </Router>
    </>
  );
}

export default App;
